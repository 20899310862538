<template lang="html">
    <div class="ps-post--detail ps-post--detail-with-hero">
        <div
            class="ps-post__thumbnail"
            :style="{
                backgroundImage: `url(${post['media:content'][0].$.url})`
            }"
            style="background-size: cover;"
        />
        <div class="ps-post__wrapper">
            <div class="ps-post__header">
                <div class="ps-post__meta">
                    <span></span>
                </div>
                <h2>{{ post.title[0] }}</h2>
            </div>
            <div class="ps-post__content">
                <div class="ps-document">
                    <div v-html="post.description[0]"></div>
                    <div style="padding: 20px;"><a :href="post.link[0]" class="ps-post__morelink effect--underline">Read the full article</a></div>
                    <!--                    <div class="ps-post__footer">
                        <ModuleArticleTags tags="{tagItems}" />
                        <ModuleArticleSocialSharing />
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { baseUrl } from '@/repositories/Repository';

export default {
    name: 'ArticleDetailWithHero',
    props: {
        post: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        baseURL() {
            return baseUrl;
        },
        postContent() {
            return this.post.content;
        }
    },
    mounted () {
        console.log(this.post);
    }
};
</script>
