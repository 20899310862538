<template lang="html">
    <main class="ps-page ps-page--single-post">
        <template v-if="post">
            <article-detail-with-hero :post="post" />
        </template>
        <p v-else>not found.</p>
    </main>
</template>

<script>
import axios from 'axios';
import ArticleDetailWithHero from '@/components/elements/posts/ArticleDetailWithHero';
export default {
    components: { ArticleDetailWithHero },
    data() {
        return {
            post: false
        };
    },
    async mounted() {
      const post = await axios.get('https://livenewsapi.com/feed/' + this.$route.params.id).catch(() => {});
      console.log(post);
      if (typeof post !== "undefined" && post.data) { this.post = post.data; }
    },
    created() {
        this.$store.commit('app/toggleDrawer', false);
    }
};
</script>

<style lang="scss" scope></style>
